import React from "react";
import styled from "styled-components";

const FooterContaienr = styled.footer`
  height: 30rem;
  width: 100%;
  background-color: var(--black-light);
`;

const Footer = () => {
  return <FooterContaienr>Footer</FooterContaienr>;
};

export default Footer;
